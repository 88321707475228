

const IconDashboard = () => (

<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0703 1.09375L12.0703 4.84375L15.8203 4.84375C16.0192 4.84375 16.21 4.92277 16.3506 5.06342C16.4913 5.20407 16.5703 5.39484 16.5703 5.59375L16.5703 16.8437L17.3203 16.8437C17.5192 16.8437 17.71 16.9228 17.8506 17.0634C17.9913 17.2041 18.0703 17.3948 18.0703 17.5938C18.0703 17.7927 17.9913 17.9834 17.8506 18.1241C17.71 18.2647 17.5192 18.3437 17.3203 18.3437L0.820312 18.3437C0.621401 18.3437 0.430634 18.2647 0.289982 18.1241C0.14933 17.9834 0.0703125 17.7927 0.0703125 17.5937C0.0703125 17.3948 0.14933 17.2041 0.289982 17.0634C0.430634 16.9228 0.621401 16.8437 0.820313 16.8437L1.57031 16.8437L1.57031 8.59375C1.57031 8.39484 1.64933 8.20407 1.78998 8.06342C1.93063 7.92277 2.1214 7.84375 2.32031 7.84375L6.07031 7.84375L6.07031 1.09375C6.07031 0.894838 6.14933 0.704071 6.28998 0.563419C6.43064 0.422767 6.6214 0.343749 6.82031 0.34375L11.3203 0.34375C11.5192 0.34375 11.71 0.422767 11.8506 0.563419C11.9913 0.704071 12.0703 0.894838 12.0703 1.09375ZM15.0703 6.34375L12.0703 6.34375L12.0703 16.8437L15.0703 16.8437L15.0703 6.34375ZM3.07031 9.34375L3.07031 16.8437L6.07031 16.8437L6.07031 9.34375L3.07031 9.34375ZM7.57031 1.84375L7.57031 16.8437L10.5703 16.8437L10.5703 1.84375L7.57031 1.84375Z" fill="#101010"/>
</svg>


);
export default IconDashboard;