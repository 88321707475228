

const IconCustom = () => (
<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7303 8.52316L14.2303 7.39316L15.3603 6.88316L14.2303 6.37316L13.7303 5.24316L13.2203 6.37316L12.0903 6.88316L13.2203 7.39316L13.7303 8.52316Z" fill="#101010"/>
    <path d="M9.63035 6.06348L8.61035 8.31348L6.36035 9.33348L8.61035 10.3635L9.63035 12.6135L10.6604 10.3635L12.9104 9.33348L10.6604 8.31348L9.63035 6.06348Z" fill="#101010"/>
    <path d="M13.7303 10.1533L13.2203 11.2833L12.0903 11.7933L13.2203 12.3033L13.7303 13.4333L14.2303 12.3033L15.3603 11.7933L14.2303 11.2833L13.7303 10.1533Z" fill="#101010"/>
    <path d="M11.2706 12.8235C11.1909 12.8222 11.1119 12.8367 11.0379 12.8663C10.9639 12.8958 10.8966 12.9398 10.8398 12.9957C10.7831 13.0515 10.738 13.1181 10.7072 13.1916C10.6764 13.265 10.6606 13.3439 10.6606 13.4235V14.4235H2.05057V4.10352H10.6606V5.21351C10.6795 5.36057 10.7514 5.49571 10.8627 5.59363C10.9741 5.69156 11.1173 5.74558 11.2656 5.74558C11.4138 5.74558 11.5571 5.69156 11.6684 5.59363C11.7798 5.49571 11.8516 5.36057 11.8706 5.21351V1.92353C11.8706 1.74493 11.8354 1.56809 11.7671 1.40308C11.6987 1.23808 11.5985 1.08815 11.4722 0.961859C11.3459 0.835572 11.196 0.735406 11.031 0.667059C10.866 0.598713 10.6892 0.563513 10.5106 0.563513H2.22058C2.04114 0.562194 1.86321 0.596405 1.69705 0.66416C1.53089 0.731916 1.37979 0.831869 1.25244 0.958289C1.12509 1.08471 1.02402 1.23511 0.955048 1.40076C0.886077 1.56642 0.850581 1.74409 0.850586 1.92353V16.7635C0.850581 16.943 0.886077 17.1206 0.955048 17.2863C1.02402 17.4519 1.12509 17.6023 1.25244 17.7287C1.37979 17.8552 1.53089 17.9551 1.69705 18.0229C1.86321 18.0906 2.04114 18.1248 2.22058 18.1235H10.5106C10.8713 18.1235 11.2172 17.9802 11.4722 17.7252C11.7273 17.4701 11.8706 17.1242 11.8706 16.7635V13.3935C11.8629 13.2397 11.7963 13.0946 11.6846 12.9885C11.5729 12.8824 11.4246 12.8233 11.2706 12.8235ZM2.05057 1.95353C2.05052 1.93165 2.05497 1.90999 2.06363 1.8899C2.07229 1.8698 2.08499 1.8517 2.10092 1.83671C2.11685 1.82171 2.1357 1.81014 2.15628 1.80271C2.17686 1.79528 2.19874 1.79216 2.22058 1.79352H10.5106C10.5513 1.79607 10.5894 1.81405 10.6173 1.84379C10.6452 1.87352 10.6607 1.91277 10.6606 1.95353V2.95353H2.05057V1.95353ZM10.6606 16.7935C10.6607 16.8343 10.6452 16.8735 10.6173 16.9033C10.5894 16.933 10.5513 16.951 10.5106 16.9535H2.22058C2.19874 16.9549 2.17686 16.9518 2.15628 16.9443C2.1357 16.9369 2.11685 16.9253 2.10092 16.9103C2.08499 16.8953 2.07229 16.8772 2.06363 16.8572C2.05497 16.8371 2.05052 16.8154 2.05057 16.7935V15.6535H10.6606V16.7935Z" fill="#101010"/>
</svg>
);
export default IconCustom;