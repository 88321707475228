

const IconOrders = () => (
<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4547 3.54526L12.6147 0.705261L12.5547 0.635254C12.4157 0.501425 12.2428 0.408051 12.0547 0.365234H1.45465C1.13956 0.367092 0.837843 0.492814 0.614655 0.71524C0.398234 0.942395 0.273551 1.24162 0.264648 1.55524V18.1552C0.267261 18.47 0.393484 18.7712 0.616089 18.9938C0.838693 19.2164 1.13985 19.3426 1.45465 19.3452H14.5347C14.8321 19.3465 15.1192 19.2364 15.3395 19.0365C15.5598 18.8366 15.6972 18.5614 15.7247 18.2653V4.26526C15.7347 4.17888 15.7347 4.09163 15.7247 4.00525C15.6821 3.82862 15.5881 3.66856 15.4547 3.54526ZM1.59467 1.70526H10.8647V4.03525C10.8645 4.19945 10.8951 4.36225 10.9547 4.51526C11.0136 4.66709 11.1059 4.80377 11.2247 4.91525C11.3335 5.02767 11.4641 5.1167 11.6086 5.17688C11.753 5.23706 11.9082 5.26713 12.0647 5.26526H14.3947V18.0352H1.59467V1.70526Z" fill="#101010"/>
    <path d="M3.66492 8.57587H10.7349C10.9071 8.57499 11.0723 8.5068 11.1949 8.38586C11.2584 8.32517 11.309 8.25223 11.3435 8.17145C11.3781 8.09067 11.3959 8.00372 11.3959 7.91586C11.3959 7.828 11.3781 7.74106 11.3435 7.66028C11.309 7.5795 11.2584 7.50655 11.1949 7.44586C11.0723 7.32493 10.9071 7.25674 10.7349 7.25586H3.66492C3.49266 7.25674 3.32756 7.32493 3.2049 7.44586C3.14137 7.50655 3.09082 7.5795 3.05627 7.66028C3.02173 7.74106 3.00391 7.828 3.00391 7.91586C3.00391 8.00372 3.02173 8.09067 3.05627 8.17145C3.09082 8.25223 3.14137 8.32517 3.2049 8.38586C3.32756 8.5068 3.49266 8.57499 3.66492 8.57587Z" fill="#101010"/>
    <path d="M3.66449 11.9052H10.7345C10.8968 11.8852 11.0462 11.8066 11.1546 11.6841C11.263 11.5617 11.3228 11.4037 11.3228 11.2402C11.3228 11.0767 11.263 10.9188 11.1546 10.7963C11.0462 10.6738 10.8968 10.5951 10.7345 10.5752H3.66449C3.50216 10.5951 3.35272 10.6738 3.24435 10.7963C3.13599 10.9188 3.07617 11.0767 3.07617 11.2402C3.07617 11.4037 3.13599 11.5617 3.24435 11.6841C3.35272 11.8066 3.50216 11.8852 3.66449 11.9052Z" fill="#101010"/>
    <path d="M3.66496 15.2253H8.75495C8.93046 15.226 9.09922 15.1577 9.22496 15.0353C9.28745 14.9744 9.3366 14.9011 9.3693 14.8202C9.40201 14.7393 9.41757 14.6525 9.41496 14.5653C9.41583 14.4783 9.39947 14.392 9.36686 14.3113C9.33425 14.2307 9.28604 14.1572 9.22496 14.0953C9.09922 13.9728 8.93046 13.9046 8.75495 13.9053H3.66496C3.49271 13.9062 3.3276 13.9743 3.20494 14.0953C3.07882 14.2194 3.00694 14.3884 3.00495 14.5653C3.00363 14.6532 3.02074 14.7405 3.05519 14.8215C3.08963 14.9024 3.14063 14.9752 3.20494 15.0353C3.3276 15.1562 3.49271 15.2244 3.66496 15.2253Z" fill="#101010"/>
    <path d="M3.66496 5.25556H7.19496C7.37093 5.25558 7.53984 5.18637 7.6652 5.06287C7.79057 4.93938 7.86231 4.77151 7.86494 4.59556C7.86627 4.50759 7.84915 4.42034 7.81471 4.33939C7.78026 4.25844 7.72926 4.18559 7.66496 4.12555C7.53922 4.00311 7.37046 3.93488 7.19496 3.93555H3.66496C3.49271 3.93643 3.3276 4.00462 3.20494 4.12555C3.14063 4.18559 3.08963 4.25844 3.05519 4.33939C3.02074 4.42034 3.00363 4.50759 3.00495 4.59556C3.00694 4.77248 3.07882 4.94145 3.20494 5.06556C3.3276 5.18649 3.49271 5.25468 3.66496 5.25556Z" fill="#101010"/>
</svg>

);
export default IconOrders;