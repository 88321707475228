import { ISvgIconProps } from '../../utils/interfaces';

const IconLogoDx = ({ width= 121, height= 33, color = '#272627' }: ISvgIconProps) => (

<svg width={width} height={height} viewBox="0 0 80 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_565_14922)">
            <path d="M70.6939 7.50488C68.8174 7.50488 66.983 8.06007 65.4228 9.10025C63.8625 10.1404 62.6464 11.6189 61.9283 13.3486C61.2102 15.0784 61.0223 16.9817 61.3884 18.818C61.7545 20.6543 62.6581 22.341 63.985 23.6649C65.3119 24.9888 67.0025 25.8904 68.8429 26.2557C70.6834 26.6209 72.5911 26.4335 74.3248 25.717C76.0585 25.0005 77.5403 23.7872 78.5828 22.2304C79.6254 20.6737 80.1818 18.8435 80.1818 16.9712C80.1818 14.4606 79.1822 12.0528 77.4029 10.2775C75.6236 8.50223 73.2103 7.50488 70.6939 7.50488ZM70.6939 22.667C69.5633 22.667 68.458 22.3325 67.5179 21.7057C66.5778 21.079 65.8451 20.1882 65.4124 19.146C64.9797 18.1037 64.8665 16.9569 65.0871 15.8505C65.3076 14.7441 65.8521 13.7278 66.6516 12.9301C67.4511 12.1324 68.4697 11.5892 69.5787 11.3691C70.6876 11.149 71.837 11.262 72.8816 11.6937C73.9262 12.1254 74.8191 12.8564 75.4472 13.7944C76.0754 14.7324 76.4107 15.8351 76.4107 16.9632C76.4117 17.7129 76.2646 18.4555 75.9778 19.1484C75.691 19.8413 75.2701 20.4711 74.7391 21.0015C74.2082 21.532 73.5776 21.9529 72.8835 22.24C72.1894 22.5272 71.4453 22.675 70.6939 22.675V22.667Z" fill={ color }/>
        <path d="M36.3004 7.50488H31.9914L26.7826 14.2001L21.5737 7.50488H17.2627L24.627 16.9712L17.2627 26.4395H21.5737L26.7826 19.7423L31.9914 26.4395H36.3004L28.9381 16.9712L36.3004 7.50488Z" fill="#272627"/>
        <path d="M15.6147 16.9712C15.6148 14.4706 14.6231 12.0715 12.8563 10.2979C11.0895 8.52425 8.69113 7.52017 6.18485 7.50488H0.200195V11.2675H6.12887C7.64503 11.2675 9.0991 11.8684 10.1712 12.9381C11.2433 14.0077 11.8456 15.4585 11.8456 16.9712C11.8456 18.4839 11.2433 19.9347 10.1712 21.0044C9.0991 22.074 7.64503 22.675 6.12887 22.675H0.200195V26.4375H6.18485C8.6913 26.4228 11.09 25.4189 12.8569 23.6451C14.6238 21.8714 15.6153 19.472 15.6147 16.9712Z" fill="#272627"/>
        <path d="M52.824 11.2675H58.6387V7.50488H52.688C50.1958 7.54098 47.8179 8.5541 46.0683 10.3253C44.3187 12.0964 43.3379 14.4834 43.3379 16.9702C43.3379 19.457 44.3187 21.844 46.0683 23.6152C47.8179 25.3863 50.1958 26.3995 52.688 26.4356H58.6387V22.675H52.824C51.3078 22.675 49.8538 22.074 48.7817 21.0044C47.7096 19.9347 47.1073 18.4839 47.1073 16.9712C47.1073 15.4585 47.7096 14.0077 48.7817 12.9381C49.8538 11.8684 51.3078 11.2675 52.824 11.2675Z" fill="#272627"/>
        <path d="M39.7832 26.3667C40.7847 26.3667 41.5967 25.5548 41.5967 24.5532C41.5967 23.5517 40.7847 22.7397 39.7832 22.7397C38.7816 22.7397 37.9697 23.5517 37.9697 24.5532C37.9697 25.5548 38.7816 26.3667 39.7832 26.3667Z" fill="#272627"/>
    </g>
    <defs>
        <clipPath id="clip0_565_14922">
            <rect width="80" height="19" fill="white" transform="translate(0.200195 7.49609)"/>
        </clipPath>
    </defs>
</svg>

);
export default IconLogoDx;