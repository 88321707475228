

const IconOrdersAvaliations = () => (
<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.74697 18.5173H2.22951V2.18563H11.4988V4.52503C11.495 4.6884 11.5243 4.85086 11.585 5.00258C11.6457 5.15431 11.7365 5.29217 11.852 5.40783C11.9622 5.51754 12.0931 5.60442 12.237 5.66349C12.3809 5.72257 12.5351 5.75268 12.6906 5.7521H15.03V10.3162C15.03 10.4918 15.0998 10.6602 15.2239 10.7843C15.3481 10.9085 15.5165 10.9783 15.6921 10.9783C15.8677 10.9783 16.0361 10.9085 16.1603 10.7843C16.2845 10.6602 16.3542 10.4918 16.3542 10.3162V4.77221C16.363 4.6871 16.363 4.60131 16.3542 4.5162C16.3186 4.32821 16.2261 4.15567 16.0894 4.02183L16.0276 3.96003L13.2468 1.17924L13.185 1.11743C13.0473 0.979397 12.8725 0.884325 12.6818 0.84377H2.08826C1.93186 0.842857 1.7769 0.873704 1.63275 0.934398C1.4886 0.995093 1.35824 1.0844 1.2496 1.19691C1.0272 1.41983 0.900526 1.72069 0.896484 2.03555V18.6321C0.896484 18.7886 0.927302 18.9435 0.987194 19.0881C1.04709 19.2327 1.13489 19.3641 1.24556 19.4748C1.35622 19.5855 1.48759 19.6732 1.63218 19.7331C1.77677 19.793 1.93176 19.8238 2.08826 19.8238H7.73814C7.91374 19.8238 8.08215 19.7541 8.20631 19.6299C8.33048 19.5058 8.40023 19.3373 8.40023 19.1617C8.40023 18.9861 8.33048 18.8178 8.20631 18.6936C8.08215 18.5694 7.91374 18.4996 7.73814 18.4996L7.74697 18.5173Z" fill="#101010"/>
    <path d="M5.09862 9.249H12.161C12.3366 9.249 12.505 9.17924 12.6291 9.05508C12.7533 8.93091 12.8231 8.7625 12.8231 8.5869C12.8231 8.4113 12.7533 8.24289 12.6291 8.11872C12.505 7.99456 12.3366 7.9248 12.161 7.9248H5.09862C4.92302 7.9248 4.75461 7.99456 4.63044 8.11872C4.50628 8.24289 4.43652 8.4113 4.43652 8.5869C4.43652 8.7625 4.50628 8.93091 4.63044 9.05508C4.75461 9.17924 4.92302 9.249 5.09862 9.249Z" fill="#101010"/>
    <path d="M7.747 11.4551H5.09862C4.92302 11.4551 4.75461 11.5248 4.63044 11.649C4.50628 11.7732 4.43652 11.9416 4.43652 12.1172C4.43652 12.2928 4.50628 12.4612 4.63044 12.5854C4.75461 12.7095 4.92302 12.7793 5.09862 12.7793H7.747C7.9226 12.7793 8.09101 12.7095 8.21518 12.5854C8.33934 12.4612 8.4091 12.2928 8.4091 12.1172C8.4091 11.9416 8.33934 11.7732 8.21518 11.649C8.09101 11.5248 7.9226 11.4551 7.747 11.4551Z" fill="#101010"/>
    <path d="M5.09862 5.71775H8.62979C8.80539 5.71775 8.9738 5.64799 9.09797 5.52383C9.22214 5.39966 9.29189 5.23125 9.29189 5.05565C9.29189 4.88005 9.22214 4.71164 9.09797 4.58747C8.9738 4.46331 8.80539 4.39355 8.62979 4.39355H5.09862C4.92302 4.39355 4.75461 4.46331 4.63044 4.58747C4.50628 4.71164 4.43652 4.88005 4.43652 5.05565C4.43652 5.23125 4.50628 5.39966 4.63044 5.52383C4.75461 5.64799 4.92302 5.71775 5.09862 5.71775Z" fill="#101010"/>
    <path d="M17.1934 15.1283C17.1389 14.9619 17.0389 14.8142 16.9047 14.7018C16.7705 14.5895 16.6075 14.517 16.4342 14.4927L14.9864 14.2631L14.342 12.8948C14.2654 12.7312 14.1465 12.591 13.9977 12.4887C13.8439 12.3933 13.6666 12.3428 13.4856 12.3428C13.3047 12.3428 13.1273 12.3933 12.9736 12.4887C12.8271 12.5875 12.711 12.725 12.6381 12.886L11.9849 14.272L10.5371 14.4927C10.365 14.5176 10.2034 14.5904 10.0707 14.7028C9.938 14.8152 9.8396 14.9626 9.78673 15.1283C9.7307 15.2962 9.72165 15.4763 9.76055 15.649C9.79945 15.8217 9.88482 15.9805 10.0074 16.1082L11.0756 17.194L10.8196 18.7301C10.7879 18.909 10.8086 19.0934 10.879 19.2609C10.9495 19.4285 11.0668 19.5721 11.2169 19.6747C11.3708 19.7861 11.5565 19.845 11.7465 19.8424C11.9068 19.8433 12.0646 19.8039 12.2056 19.7276L13.4856 19.0214L14.7745 19.7276C14.9278 19.8122 15.1017 19.852 15.2764 19.8426C15.4512 19.8333 15.6199 19.7751 15.7632 19.6747C15.9109 19.5703 16.0258 19.426 16.0946 19.2588C16.1634 19.0915 16.1832 18.9081 16.1517 18.7301L15.9045 17.194L16.9639 16.1082C17.0897 15.9825 17.1779 15.8241 17.2184 15.651C17.259 15.4778 17.2503 15.2968 17.1934 15.1283ZM14.748 16.4966C14.6751 16.5715 14.6204 16.6623 14.5882 16.7618C14.5561 16.8614 14.5473 16.967 14.5627 17.0704L14.748 18.1827L13.8652 17.6795C13.7702 17.6196 13.6597 17.5889 13.5474 17.5913C13.4353 17.5904 13.3252 17.621 13.2296 17.6795L12.3468 18.1827L12.5322 17.0704C12.5497 16.9669 12.542 16.8607 12.5097 16.7608C12.4774 16.661 12.4216 16.5703 12.3468 16.4966L11.5523 15.6756L12.6293 15.5167C12.7365 15.499 12.838 15.4562 12.9254 15.3917C13.0129 15.3273 13.0838 15.243 13.1325 15.1459L13.5827 14.1749L14.0418 15.1459C14.0896 15.242 14.1592 15.3255 14.245 15.3899C14.3309 15.4543 14.4306 15.4977 14.5362 15.5167L15.622 15.6756L14.748 16.4966Z" fill="#101010"/>
</svg>
);
export default IconOrdersAvaliations;