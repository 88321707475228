

const IconCoupon = () => (
<svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8255 0.180664C12.2313 0.180664 12.5602 0.509598 12.5602 0.915358V2.75209C12.5602 3.15785 12.2313 3.48679 11.8255 3.48679C11.4198 3.48679 11.0908 3.15785 11.0908 2.75209V0.915358C11.0908 0.509598 11.4198 0.180664 11.8255 0.180664Z" fill="#101010"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8255 5.69043C12.2313 5.69043 12.5602 6.01936 12.5602 6.42512V8.26186C12.5602 8.66762 12.2313 8.99655 11.8255 8.99655C11.4198 8.99655 11.0908 8.66762 11.0908 8.26186V6.42512C11.0908 6.01936 11.4198 5.69043 11.8255 5.69043Z" fill="#101010"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8255 11.2012C12.2313 11.2012 12.5602 11.5301 12.5602 11.9359V13.7726C12.5602 14.1784 12.2313 14.5073 11.8255 14.5073C11.4198 14.5073 11.0908 14.1784 11.0908 13.7726V11.9359C11.0908 11.5301 11.4198 11.2012 11.8255 11.2012Z" fill="#101010"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.64174 1.65005C2.34946 1.65005 2.06915 1.76616 1.86248 1.97283C1.65581 2.1795 1.5397 2.45981 1.5397 2.75209V4.87969C1.94529 5.00062 2.31858 5.22096 2.62328 5.52566C3.10552 6.00789 3.37644 6.66194 3.37644 7.34393C3.37644 8.02592 3.10552 8.67997 2.62328 9.1622C2.31858 9.4669 1.94529 9.68724 1.5397 9.80817V11.9358C1.5397 12.228 1.65581 12.5084 1.86248 12.715C2.06915 12.9217 2.34946 13.0378 2.64174 13.0378H15.4989C15.7912 13.0378 16.0715 12.9217 16.2781 12.715C16.4848 12.5084 16.6009 12.228 16.6009 11.9358V9.80817C16.1953 9.68724 15.822 9.4669 15.5173 9.1622C15.0351 8.67997 14.7642 8.02592 14.7642 7.34393C14.7642 6.66194 15.0351 6.00789 15.5173 5.52566C15.822 5.22096 16.1953 5.00062 16.6009 4.87969V2.75209C16.6009 2.45981 16.4848 2.17951 16.2781 1.97283C16.0715 1.76616 15.7912 1.65005 15.4989 1.65005H2.64174ZM0.823466 0.933818C1.3057 0.451582 1.95976 0.180664 2.64174 0.180664H15.4989C16.1809 0.180664 16.8349 0.451582 17.3172 0.933818C17.7994 1.41605 18.0703 2.07011 18.0703 2.75209V5.5072C18.0703 5.91296 17.7414 6.24189 17.3356 6.24189C17.0433 6.24189 16.763 6.358 16.5564 6.56467C16.3497 6.77134 16.2336 7.05165 16.2336 7.34393C16.2336 7.63621 16.3497 7.91652 16.5564 8.12319C16.763 8.32986 17.0433 8.44597 17.3356 8.44597C17.7414 8.44597 18.0703 8.77491 18.0703 9.18067V11.9358C18.0703 12.6178 17.7994 13.2718 17.3172 13.754C16.8349 14.2363 16.1809 14.5072 15.4989 14.5072H2.64174C1.95976 14.5072 1.3057 14.2363 0.823466 13.754C0.34123 13.2718 0.0703125 12.6178 0.0703125 11.9358V9.18067C0.0703125 8.77491 0.399246 8.44597 0.805006 8.44597C1.09729 8.44597 1.37759 8.32986 1.58427 8.12319C1.79094 7.91652 1.90705 7.63621 1.90705 7.34393C1.90705 7.05165 1.79094 6.77134 1.58427 6.56467C1.37759 6.358 1.09729 6.24189 0.805006 6.24189C0.399246 6.24189 0.0703125 5.91296 0.0703125 5.5072V2.75209C0.0703125 2.07011 0.34123 1.41605 0.823466 0.933818Z" fill="#101010"/>
</svg>

);
export default IconCoupon;