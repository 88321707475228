import { ISvgIconProps } from '../../utils/interfaces';

const IconHamburger = ({ width= 25, height= 17, color = '#101010' }: ISvgIconProps) => (

<svg width={width} height={height} viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7685 7.17871H1.30182C0.602262 7.17871 0.0351562 7.74582 0.0351562 8.44538V8.57871C0.0351562 9.27827 0.602262 9.84538 1.30182 9.84538H22.7685C23.4681 9.84538 24.0352 9.27827 24.0352 8.57871V8.44538C24.0352 7.74582 23.4681 7.17871 22.7685 7.17871Z" fill={color}/>
        <path d="M22.7685 13.8447H1.30182C0.602262 13.8447 0.0351562 14.4118 0.0351562 15.1114V15.2447C0.0351562 15.9443 0.602262 16.5114 1.30182 16.5114H22.7685C23.4681 16.5114 24.0352 15.9443 24.0352 15.2447V15.1114C24.0352 14.4118 23.4681 13.8447 22.7685 13.8447Z" fill={color}/>
    <path d="M22.7685 0.511719H1.30182C0.602262 0.511719 0.0351562 1.07882 0.0351562 1.77839V1.91172C0.0351562 2.61128 0.602262 3.17839 1.30182 3.17839H22.7685C23.4681 3.17839 24.0352 2.61128 24.0352 1.91172V1.77839C24.0352 1.07882 23.4681 0.511719 22.7685 0.511719Z" fill={color}/>
</svg>


);
export default IconHamburger;